export function createCombinedAddress(address?: AccountSite): string {
  if (!address)
    return ''
  const addressParts = [address.addressLine1]
  if (address.city && address.postalCode) {
    addressParts.push(`${address.postalCode} ${address.city}`)
  }
  else if (address.city) {
    addressParts.push(address.city)
  }
  return addressParts.join(', ')
}
